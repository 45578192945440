<template>
  <div part="concept" class="container is-centered">
    <div class="columns is-centered">
      <div class="column is-6-mobile margin">
        <v-img
          class="lazboy-logo"
          src="@/assets/beyound.png"
          alt="Thank-you"
        />
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-12-desktop">
        <div class="content">
          <p>
            Not just A Recliner or Sofa, La-Z-Boy offers ways beyond the
            furniture. We offer comfort experiences to make house into home. We
            never stop developing new innovation to bring the next level of
            comfort for our customers. Our new innovative functions, design and
            value are keys to drive our brand and products to satisfy our
            customers’ desires.
          </p>
          <p>
            Not only the people that we care but also our planet. Our products
            are made from environmentally friendly materials and our production
            is aim to decrease carbon footprint which affects the Global Warming
            situation. At La-Z-Boy, we implement the reuse of our scraps from
            production to produce cover options for those who care for the
            Earth.
          </p>
          <p>
            La-Z-Boy also helps the community and charity by being part of the
            campaign “Ronald McDonald House” we believe that everyone should
            Live one’s Life Comfortably.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./../assets/css/_concept.scss";
.margin {
  margin: auto;
}
.content {
  max-width: 746px;
  margin: auto;
  line-height: 24px;
}

p {
  font-size: 15px;
  text-align: center;
  text-align-last: center;
}
</style>
<script>
export default {
  created() {
    document
      .getElementsByClassName("main-container")[0]
      .classList.add("concept");
  },
  beforeDestroy() {
    document
      .getElementsByClassName("main-container")[0]
      .classList.remove("concept");
  },
};
</script>
